<template>
  <nav class="bg-primary fixed z-40 w-full shadow-lg">
    <div class="px-2 sm:px-4 lg:px-8">
      <div class="relative h-16 flex items-center justify-between">
        <div class="px-2 flex items-center lg:px-0">
          <router-link to="/" class="flex-shrink-0">
            <img class="block h-24 filter contrast-125" src="@/assets/vw-logo.svg" alt="VW Soluções">
          </router-link>
          <div class="hidden lg:block lg:ml-10">
            <div class="flex space-x-4">
              <router-link to="/" class="text-white rounded-md py-2 px-3 text-sm font-medium" :class="$route.name === 'Inicio' ? 'bg-primarydark ' : 'hover:bg-primarylight hover:bg-opacity-75'">Início</router-link>
              <router-link to="/produtos" class="text-white rounded-md py-2 px-3 text-sm font-medium" :class="$route.name === 'Produtos' ? 'bg-primarydark ' : 'hover:bg-primarylight hover:bg-opacity-75'">Produtos</router-link>
              <router-link to="/suporte" class="text-white rounded-md py-2 px-3 text-sm font-medium" :class="$route.name === 'Suporte' ? 'bg-primarydark ' : 'hover:bg-primarylight hover:bg-opacity-75'">Suporte</router-link>
              <router-link to="/downloads" class="text-white rounded-md py-2 px-3 text-sm font-medium" :class="$route.name === 'Downloads' ? 'bg-primarydark ' : 'hover:bg-primarylight hover:bg-opacity-75'">Downloads</router-link>
              <router-link to="/aprenda" class="text-white rounded-md py-2 px-3 text-sm font-medium" :class="$route.name === 'Aprenda' ? 'bg-primarydark ' : 'hover:bg-primarylight hover:bg-opacity-75'">Aprenda</router-link>
              <router-link v-if="$route.name !== 'Inicio'" to="/contato" class="text-white rounded-md py-2 px-3 text-sm font-medium">Contato</router-link>
              <button v-else @click="scroll()" class="text-white rounded-md py-2 px-3 text-sm font-medium">Contato</button>
            </div>
          </div>
        </div>
        <div class="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end">
          <SearchNav />
        </div>
        <div class="flex lg:hidden">
          <button @click="menu = !menu" type="button" class="bg-primarydark p-2 rounded-md inline-flex items-center justify-center text-gray-200 hover:text-white hover:bg-primarylight hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Abrir menu principal</span>
            <svg v-if="menu" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
            <svg v-else class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
          </button>
        </div>
        <div class="hidden lg:block">
          <div class="flex items-center">
            <router-link to="/carrinho" :class="currentUser ? 'rounded-full' : 'rounded-md'" class="p-2 bg-primarydark flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>
            </router-link>
            <div v-if="currentUser" class="ml-3 relative flex-shrink-0">
              <div>
                <button @click="user = !user" type="button" class="bg-primarydark rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white" id="user-menu" aria-expanded="false" aria-haspopup="true">
                  <span class="sr-only">Abrir menu do usuário</span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-white" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" /></svg>
                </button>
              </div>
              <transition enter-class="opacity-0" enter-active-class="transition ease-in duration-50 transform" enter-to-class="opacity-100" leave-class="opacity-100" leave-active-class="transition ease-in duration-50 transform" leave-to-class="opacity-0">
                <div v-show="user" class="z-10 fixed inset-0 transition-opacity">
                  <div @click="user = false" class="absolute inset-0 bg-black opacity-30" tabindex="0"></div>
                </div>
              </transition>
              <transition enter-active-class="transition ease-out duration-150" enter-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-150" leave-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <div v-if="user" @click="user = false" class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <router-link v-if="$store.state.user.Permission === 'Admin'" to="/admin/painel" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Painel Administrativo</router-link>
                  <router-link to="/conta" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Minha Conta</router-link>
                  <div @click="$store.dispatch('logout')" class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Sair</div>
                </div>
              </transition>
            </div>
            <div v-else class="ml-3 relative flex-shrink-0">
              <div @click="$modal.show('login')" class="h-full text-white rounded-md py-2 px-3 text-sm font-medium bg-primarydark ">Login</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition enter-active-class="transition ease-in duration-300" enter-class="opacity-0 -translate-y-1" enter-to-class="opacity-100 translate-y-0">
      <div v-if="menu" @click="menu = false" class="lg:hidden" id="mobile-menu">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <router-link to="/" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Inicio' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" /></svg>
            Início
          </router-link>
          <router-link to="/produtos" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Produtos' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor"><path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" /></svg>
            Produtos
          </router-link>
          <router-link to="/suporte" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Suporte' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd" /></svg>
            Suporte
          </router-link>
          <router-link to="/downloads" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Downloads' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 24 24"><path fill="currentColor" d="M9 1v6H5l7 7l7-7h-4V1H9M5 16v2h14v-2H5m0 4v2h14v-2H5Z"/></svg>
            Downloads
          </router-link>
          <router-link to="/aprenda" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Aprenda' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor"><path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z" /></svg>
            Aprenda
          </router-link>
          <router-link v-if="$route.name !== 'Inicio'" to="/contato" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium hover:bg-primarylight hover:bg-opacity-75">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 24 24"><path fill="currentColor" d="M10.5 20h3q.2 0 .35-.15t.15-.35q0-.2-.15-.35T13.5 19h-3q-.2 0-.35.15t-.15.35q0 .2.15.35t.35.15ZM7 23q-.825 0-1.413-.588T5 21V3q0-.825.588-1.413T7 1h10q.825 0 1.413.588T19 3v18q0 .825-.588 1.413T17 23H7Zm0-7h10V6H7v10Z"/></svg>
            Contato
          </router-link>
          <button @click="scroll()" v-else class="w-full text-white flex items-center rounded-md py-2 px-3 text-base font-medium hover:bg-primarylight hover:bg-opacity-75">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 24 24"><path fill="currentColor" d="M10.5 20h3q.2 0 .35-.15t.15-.35q0-.2-.15-.35T13.5 19h-3q-.2 0-.35.15t-.15.35q0 .2.15.35t.35.15ZM7 23q-.825 0-1.413-.588T5 21V3q0-.825.588-1.413T7 1h10q.825 0 1.413.588T19 3v18q0 .825-.588 1.413T17 23H7Zm0-7h10V6H7v10Z"/></svg>
            Contato
          </button>
          <router-link to="/carrinho" class="text-white flex items-center rounded-md py-2 px-3 text-base font-medium" :class="$route.name === 'Carrinho' ? 'bg-primarydark' : 'hover:bg-primarylight hover:bg-opacity-75'">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" viewBox="0 0 20 20" fill="currentColor"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" /></svg>
            Carrinho
          </router-link>
          <div v-if="!currentUser" @click="$modal.show('login')" class="text-white block rounded-md py-2 px-3 text-base border-2 font-medium hover:bg-primarylight hover:bg-opacity-75">
            Login
          </div>
        </div>
        <div v-if="currentUser" class="pt-4 pb-3 border-t border-primarydark">
          <div class="px-5 flex items-center">
            <div class="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10 text-white" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" /></svg>
            </div>
            <div class="min-w-0 ml-3">
              <div class="text-base font-medium text-white">{{ $store.state.user.Name }}</div>
              <div class="text-sm font-medium text-gray-300">{{ $store.state.user.Email.substring(0, 25) + '...' }}</div>
            </div>
            <button @click="$store.dispatch('logout')" class="ml-auto bg-primarydark flex-shrink-0 rounded-full p-2 text-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-600 focus:ring-white">
              <span class="sr-only">Sair</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>
            </button>
          </div>
          <div class="mt-3 px-2 space-y-1">
            <router-link v-if="$store.state.user.Permission === 'Admin'" to="/admin/painel" class="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-primarylight hover:bg-opacity-75">Painel Administrativo</router-link>
            <router-link to="/conta" class="block rounded-md py-2 px-3 text-base font-medium text-white hover:bg-primarylight hover:bg-opacity-75">Minha Conta</router-link>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import { auth } from '@/firebase'

export default {
  components: { SearchNav: () => import('@/components/search/nav') },

  data() {
    return {
      menu: false,
      user: false,
      currentUser: auth.currentUser,
    }
  },

  methods: { scroll() { window.scrollTo(0, (document.body.scrollHeight-800)) } }
}
</script>